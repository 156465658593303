<template>
  <div
    class="grid-component-preview"
    :class="{
      [mode]: mode,
      higher: c.active,
      background: c.component === 'dashboard-section',
      [c.config.style]: c.config.style,
    }"
    :style="styleObj"
  >
    <div
      class="inner-grid-component"
      @click.self="activate"
      :class="{ active: c.active && mode !== 'preview' }"
    >
      <div v-if="mode === 'component'" @mousedown="start">
        <component :is="c.component" :config="c.config" :mode="'preview'" />
      </div>
      <div v-if="mode === 'configuration'" @mousedown="start">
        <component :is="c.component" :config="c.config" :mode="'preview'" />
      </div>
      <div v-if="mode === 'preview'" @click="activate">
        <component :is="c.component" :config="c.config" />
      </div>
      <div
        class="mover"
        @mousedown.self="start"
        @contextmenu="openContextMenu"
        v-if="mode === 'drawing'"
      >
        <div
          class="resize-bar resize-x left"
          @mousedown="startResize($event, 'x', 'left')"
        ></div>
        <div
          class="resize-bar resize-x right"
          @mousedown="startResize($event, 'x', 'right')"
        ></div>
        <div
          class="resize-bar resize-y top"
          @mousedown="startResize($event, 'y', 'top')"
        ></div>
        <div
          class="resize-bar resize-y bottom"
          @mousedown="startResize($event, 'y', 'bottom')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentPicker from "./ComponentPicker";
import DashboardGraph from "@/components/dashboards/DashboardGraph.vue";
import DashboardGraphSql from "@/components/dashboards/DashboardGraphSql.vue";
import DashboardGraphLocation from "@/components/dashboards/DashboardGraphLocation.vue";
import DashboardSection from "@/components/dashboards/DashboardSection.vue";
import ProductionValue from "@/components/dashboards/ProductionValue.vue";
import DashboardParent from "@/components/dashboards/DashboardParent.vue";
import DashboardStatus from "@/components/dashboards/DashboardStatus.vue";
import DashboardGauge from "@/components/dashboards/DashboardGauge.vue";
import DashboardSqlGauge from "@/components/dashboards/DashboardSqlGauge.vue";
import DashboardLean from "@/components/dashboards/DashboardLean.vue";
import DashboardTimeHistory from "@/components/dashboards/DashboardTimeHistory.vue";
import DashboardProductionGoal from "@/components/dashboards/DashboardProductionGoal.vue";
import DashboardOrderSummary from "@/components/dashboards/DashboardOrderSummary.vue";
import DashboardTable from "@/components/dashboards/DashboardTable.vue";
import FreeText from "@/components/dashboards/FreeText";
import SpecialDashboardComponent from "@/components/dashboards/SpecialDashboardComponent";
import _ from "lodash";
export default {
  props: {
    c: {
      required: true,
    },
    config: {
      required: true,
    },
    mode: {
      required: true,
    },
  },
  emits: ["activate", "remove"],
  computed: {
    styleObj() {
      return {
        position: "absolute",
        left: this.c.pos.x * (100 / this.config.size.x) + "%",
        top: this.c.pos.y * (100 / this.config.size.y) + "%",
        width: this.c.pos.w * (100 / this.config.size.x) + "%",
        height: this.c.pos.h * (100 / this.config.size.y) + "%",
      };
    },
  },
  data() {
    return {
      startX: this.c.pos.x,
      startW: this.c.pos.w,
      startY: this.c.pos.y,
      startH: this.c.pos.h,
      mouseDownPos: null,
      mousePos: null,
      mouseUpPos: null,
      resize: null,
    };
  },
  methods: {
    activate() {
      this.$emit("activate", this.c);
    },
    remove() {
      this.$emit("remove", this.c);
    },
    openContextMenu(e) {
      //console.warn("CONTEXT");
      e.preventDefault();
    },
    posFromEvent(e) {
      const grid = document
        .getElementById("grid-drawer")
        .getBoundingClientRect();
      return {
        x: _.clamp(
          Math.floor(
            (e.clientX - grid.left) / (grid.width / this.config.size.x)
          ),
          0,
          this.config.size.x
        ),
        y: _.clamp(
          Math.floor(
            (e.clientY - grid.top) / (grid.height / this.config.size.y)
          ),
          0,
          this.config.size.y
        ),
      };
    },
    start(e) {
      this.startX = this.c.pos.x;
      this.startY = this.c.pos.y;
      this.startW = this.c.pos.w;
      this.startH = this.c.pos.h;
      this.mouseDownPos = this.posFromEvent(e);
      document.addEventListener("mousemove", this.move);
      document.addEventListener("mouseup", this.endMove);
    },
    move(e) {
      //console.warn("moving", e);
      this.mousePos = this.posFromEvent(e);
      this.c.pos.x = _.clamp(
        this.startX + (this.mousePos.x - this.mouseDownPos.x),
        0,
        this.config.size.x - this.c.pos.w
      );
      this.c.pos.y = _.clamp(
        this.startY + (this.mousePos.y - this.mouseDownPos.y),
        0,
        this.config.size.y - this.c.pos.h
      );
      e.preventDefault();
    },
    endMove(e) {
      //console.warn("endmove", e);
      document.removeEventListener("mousemove", this.move);
      document.removeEventListener("mouseup", this.endMove);
      if (e.target && e.target.id === "discardcomp") {
        this.$emit("remove", this.c);
      }
      this.mouseUpPos = this.posFromEvent(e);
      this.startX = this.c.pos.x;
      this.startY = this.c.pos.y;
      if (
        this.mouseUpPos.x === this.mouseDownPos.x &&
        this.mouseUpPos.y === this.mouseDownPos.y
      ) {
        this.activate();
      }
      e.preventDefault();
    },
    startResize(e, dir, side) {
      //console.warn(e, dir, side);
      this.startX = this.c.pos.x;
      this.startY = this.c.pos.y;
      this.startW = this.c.pos.w;
      this.startH = this.c.pos.h;
      this.mouseDownPos = this.posFromEvent(e);
      this.resize = {
        dir,
        side,
      };
      document.addEventListener("mousemove", this.resizeMove);
      document.addEventListener("mouseup", this.endResize);
    },
    resizeMove(e) {
      //console.warn("resizing", e, this.resize);
      this.mousePos = this.posFromEvent(e);
      if (this.resize.dir === "x") {
        //resizing x &|| w
        if (this.resize.side === "right") {
          //resize w
          this.c.pos.w = _.clamp(
            this.startW + (this.mousePos.x - this.mouseDownPos.x),
            1,
            this.config.size.x - this.startX
          );
        } else {
          //move x, resize w
          this.c.pos.x = _.clamp(
            this.startX + (this.mousePos.x - this.mouseDownPos.x),
            0,
            this.startW + this.startX - 1
          );
          this.c.pos.w = this.startW + this.startX - this.c.pos.x;
        }
      } else {
        //resizing y &|| h
        if (this.resize.side === "bottom") {
          //resize h
          this.c.pos.h = _.clamp(
            this.startH + (this.mousePos.y - this.mouseDownPos.y),
            1,
            this.config.size.y - this.startY
          );
        } else {
          //move x, resize w
          this.c.pos.y = _.clamp(
            this.startY + (this.mousePos.y - this.mouseDownPos.y),
            0,
            this.startH + this.startY - 1
          );
          this.c.pos.h = this.startH + this.startY - this.c.pos.y;
        }
      }

      e.preventDefault();
    },
    endResize(e) {
      //console.warn("endmove", e);
      this.mouseUpPos = this.posFromEvent(e);
      this.startX = this.c.pos.x;
      this.startY = this.c.pos.y;
      this.startW = this.c.pos.w;
      this.startH = this.c.pos.h;
      document.removeEventListener("mousemove", this.resizeMove);
      document.removeEventListener("mouseup", this.endResize);
      e.preventDefault();
    },
  },
  components: {
    ComponentPicker,
    DashboardGraph,
    DashboardGraphSql,
    DashboardGraphLocation,
    ProductionValue,
    DashboardParent,
    FreeText,
    DashboardSection,
    DashboardStatus,
    DashboardGauge,
    DashboardSqlGauge,
    DashboardLean,
    DashboardTimeHistory,
    DashboardProductionGoal,
    DashboardOrderSummary,
    DashboardTable,
    SpecialDashboardComponent
  },
};
</script>

<style>
</style>